import React from 'react';

const LinkedinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="25"
      viewBox="0 0 26 25"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#231F20">
          <path
            d="M26 24.996h-5.056v-.293c0-2.87.004-5.74-.005-8.61-.002-.417-.032-.842-.11-1.252-.22-1.145-.85-1.755-2.05-1.877-.661-.068-1.348-.04-2.006.059-.928.14-1.413.765-1.527 1.808-.038.352-.06.708-.06 1.062-.005 2.907-.003 5.813-.003 8.72V25H10V8h4.948v2.706c.363-.316.68-.595 1-.869 1.264-1.074 2.722-1.648 4.381-1.643 1.38.004 2.717.22 3.87 1.06 1.08.788 1.584 1.898 1.742 3.194.013.108.04.215.059.322v12.226zM1 25L6 25 6 8 1 8zM3.01 0C4.666.001 6 1.34 6 2.998 5.999 4.654 4.669 5.989 3.009 6 1.357 6.01-.008 4.64 0 2.982.008 1.327 1.35 0 3.01 0"
            transform="translate(-781 -2834) translate(738 2834) translate(43)"
          />
        </g>
      </g>
    </svg>
  );
};
export { LinkedinIcon };
