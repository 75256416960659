/* eslint-disable no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import {
  PageLayout,
  CircleExpandInOut,
  SlideUpContent,
  SlideInUpHeading,
  HighlightSummaryAnimation,
  CTALink,
} from 'components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { LinkedinIcon } from 'assets/tsx/linkedin-icon';
import { MailIcon } from 'assets/tsx/mail-icon';
import ReactMarkdown from 'react-markdown';

const BlogDetails: FC = ({ data, pageContext }: any) => {
  const { mailSubject, mailBody } = data.site.siteMetadata;
  const {
    title,
    summary,
    body,
    date,
    slug,
    bannertext,
    bannerimage,
    authorName,
  } = data.BlogDetails;
  const { next } = pageContext;
  const image = bannerimage ? bannerimage.childImageSharp.fluid.src : null;
  const [location, setlocation] = useState('');
  useEffect(() => {
    setlocation(window.location.href);
  }, []);
  const linkedinLink = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
        location
      )}&title=${{ title }}&summary=${{
        summary,
      }}&source=LinkedIn`,
      '',
      '_blank,width=500, height=500, resizable=yes, scrollbars=yes'
    );
    return false;
  };

  return (
    <PageLayout
      title="blogdetails"
      quotation={data.BlogPage.quotation.content}
      className="container"
      metaTitle={title}
      description={summary}
      image={image}
    >
      <div className="blog-detail-page">
        <h4 className="page-title"> &gt; {data.BlogPage.title}</h4>
        <h2 className="blog-detail-page-heading">
          <SlideInUpHeading delaySec={0.3}>{title}</SlideInUpHeading>
        </h2>
        <div className="blog-detail-page-wrapper">
          <CircleExpandInOut bannertext={bannertext} />
          <div className="blog-detail-page-wrapper-image">
            {bannerimage?.childImageSharp?.fluid && (
              <Img
                className="blog_items_wrapper_banner"
                fluid={bannerimage.childImageSharp.fluid}
              />
            )}
          </div>
        </div>
        <div className="blog-detail-page-">
          <p className="blog-detail-page-date"> {date}</p>
          <div className="blog-detail-page-summary">
            <h4 className="blog-detail-page-summary-wrapper">
              <HighlightSummaryAnimation>{summary}</HighlightSummaryAnimation>
            </h4>
          </div>
          <div className="blog-detail-page-content">
            <SlideUpContent className="blog-detail-page-content-wrapper">
              <ReactMarkdown>{body}</ReactMarkdown>
            </SlideUpContent>
            <div className="blog-detail-page-content-wrapper-item">
              <div className="blog-detail-page-content-wrapper-item-title">
                <h3>{authorName}</h3>
                {next && <CTALink to={`blog/${next.slug}`}>Read next</CTALink>}
                <div className="blog-detail-page-content-wrapper-icon">
                  <div className="blog-detail-page-content-wrapper-mail-icon">
                    <a
                      href={`mailto:?subject=${mailSubject}&body=${mailBody}${slug}`}
                    >
                      <MailIcon />
                    </a>
                  </div>
                  <div
                    className="blog-detail-page-content-wrapper-linkedin-icon"
                    role="button"
                    tabIndex={0}
                    onClick={linkedinLink}
                    onKeyDown={linkedinLink}
                  >
                    <LinkedinIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BlogDetails;

export const query = graphql`
  query BlogDetailsQuery($slug: String!) {
    site {
      siteMetadata {
        mailSubject
        mailBody
      }
    }
    BlogPage: strapiBlogPage {
      title
      quotation {
        content
      }
    }
    BlogDetails: strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      summary
      body
      authorName
      date(formatString: "MMMM DD, YYYY")
      bannertext
      bannerimage {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
